import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/Modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { QRCodeModule } from 'angularx-qrcode';
import { BsDropdownModule, BsDropdownConfig  } from 'ngx-bootstrap/dropdown';

// import { NgxPayPalModule } from 'ngx-paypal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PromovideoComponent } from './promovideo/promovideo.component';
import { DonatePerViewModalComponent } from './donate-per-view-modal/donate-per-view-modal.component';
import { ContactusComponent } from './contactus/contactus.component';
import { TermsconditionComponent } from './termscondition/termscondition.component';
import { GuestticketComponent } from './guestticket/guestticket.component';
import { HomeComponent } from './home/home.component';
import { GuestComponent } from './guest/guest.component';
import { StripeformComponent } from './stripeform/stripeform.component';
import { WatchwithticketComponent } from './watchwithticket/watchwithticket.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { EnterTicketCodeComponent } from './enter-ticket-code/enter-ticket-code.component';
import { LiveVideoPlayerComponent } from './live-video-player/live-video-player.component';
import { SupportComponent } from './support/support.component';
import { SharedModule } from './shared/shared.module';
import { ExtravideoComponent } from './extravideo/extravideo.component';
import { ChannelvideoComponent } from './channelvideo/channelvideo.component';
import { VimeoplayerComponent } from './vimeoplayer/vimeoplayer.component';
import { AuthModule } from './auth/auth.module';
import { AddthisComponent } from './addthis/addthis.component';
import { FirsttowatchComponent } from './firsttowatch/firsttowatch.component';
import { RecordcontractComponent } from './recordcontract/recordcontract.component';
import { RidekindinfoComponent } from './ridekindinfo/ridekindinfo.component';
import { RidekinditineraryComponent } from './ridekinditinerary/ridekinditinerary.component';
import { ChanallHomeComponent } from './chanall-home/chanall-home.component';
import { SuccessMessageComponent } from './success-message/success-message.component';
import { FailMessageComponent } from './fail-message/fail-message.component';
import { RegisterMessageComponent } from './register-message/register-message.component';
import { LightboxModule } from 'ngx-lightbox';
import { Event1Component } from './event1/event1.component';
import { Event2Component } from './event2/event2.component';
import { Event3Component } from './event3/event3.component';
import { Event4Component } from './event4/event4.component';
import { HowtowatchontvComponent } from './howtowatchontv/howtowatchontv.component';
import { SponsorlinksComponent } from './sponsorlinks/sponsorlinks.component';
import { SuccessMessageLEComponent } from './success-message-le/success-message-le.component';
import { WatchwithticketcodeComponent } from './watchwithticketcode/watchwithticketcode.component';
import { EnterTicketCodeAllComponent } from './enter-ticket-code-all/enter-ticket-code-all.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    PromovideoComponent,
    DonatePerViewModalComponent,
    ContactusComponent,
    TermsconditionComponent,
    GuestticketComponent,
    HomeComponent,
    GuestComponent,
    StripeformComponent,
    WatchwithticketComponent,
    MyProfileComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    SpinnerComponent,
    EnterTicketCodeComponent,
    LiveVideoPlayerComponent,
    SupportComponent,
    ExtravideoComponent,
    ChannelvideoComponent,
    VimeoplayerComponent,
    AddthisComponent,
    FirsttowatchComponent,
    RecordcontractComponent,
    RidekindinfoComponent,
    RidekinditineraryComponent,
    ChanallHomeComponent,
    SuccessMessageComponent,
    FailMessageComponent,
    RegisterMessageComponent,
    Event1Component,
    Event2Component,
    Event3Component,
    Event4Component,
    HowtowatchontvComponent,
    SponsorlinksComponent,
    SuccessMessageLEComponent,
    WatchwithticketcodeComponent,
    EnterTicketCodeAllComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgSelectModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    // NgxPayPalModule,
    AppRoutingModule,
    SharedModule,
    QRCodeModule,
    AuthModule,
    LightboxModule 
  ],
  exports:[
    NgSelectModule
  ],
  bootstrap: [AppComponent],
  providers: [
    BsDropdownConfig,
    { provide: APP_BASE_HREF, useValue: '/' } 
  ]
})
export class AppModule { }
