import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';

import { CommonUtilsService } from '../../shared/services/common-utils.service';
import { FetchJsonService } from '../../shared/services/fetch-json.service';
import { AuthRequest, LoginService, AuthResponse } from '../../login/login.service';
import { AppStorage, MessageFor } from '../../providers/enum';
import { RegisterAction, AccountActivationAction } from '../../providers/constant';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { LoginComponent } from '../../login/login.component';
import { StorageService } from '../../shared/services/storage.service';
import { StreamDetailsService } from '../../shared/services/stream-details.service';
@Component({
  selector: 'app-register-route',
  templateUrl: './register-route.component.html',
  styleUrls: ['./register-route.component.scss']
})
export class RegisterRouteComponent implements OnInit {

  //#region variables

  public registerMessage: any;
  public accountActivationMessage: any;
  public errorMessage: string;
  public showLoading = false;
  public isCheckValidation = false;
  public isSignUp: boolean = true;
  public isSignUpSucc: boolean = false;
  public title: string;

  public registerFormGroup: FormGroup;
  public accountActivationFormGroup: FormGroup;
  public streamDetails;
  public DomainName: string;
  private subs = new SubSink();
  public streamCorrect = true;
  //#endregion

  //#region life-cycle hook

  constructor(
    private formBuilder: FormBuilder,
    private fetchJsonService: FetchJsonService,
    private loginService: LoginService,
    private registerService: AuthService,
    private storageService: StorageService,
    private streamDetailsService: StreamDetailsService,
    private router: Router) { 

      this.storageService.set(AppStorage.siteDomain, "celebration", true);
      this.storageService.set(AppStorage.siteTitle, "celebbroadway", true);
    }

  ngOnInit(): void {
    this.setStreamDetails();
    //this.href = this.router.url;
   // this.DomainName = "celebbroadway";

    let details = this.storageService.get(AppStorage.streamDetails, true);
    let streamTitle = this.storageService.get(AppStorage.siteTitle,  true);
    this.title = streamTitle;
    this.streamDetails = JSON.parse(details);
   // console.log("st=",this.streamDetails);
    this.getRegisterMessage();
    this.createRegisterFormGroup();
    this.createAccountActivationFormGroup();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  private async setStreamDetails() {
    let data = this.storageService.get(AppStorage.data, true);
   
    this.streamCorrect = true;
    try {
      let result = await this.streamDetailsService.getStreamDetails().toPromise();
      // console.log("stream detail:", result);
      if (result.app.status) {
        this.storageService.set(AppStorage.streamDetails, JSON.stringify(result.app.all_streams.latest_streams), true);
      }
      else {
        this.streamCorrect = false;
      }
    } catch (error) {
      //console.log('error: ', error);
      this.showLoading = false;
      this.streamCorrect = false;
    }

    
  }
  //#region private methods

  private getRegisterMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.registerMessage = messageList[MessageFor.RegisterForm];
        this.accountActivationMessage = messageList[MessageFor.AccountActivationFrom];
      });
  }

  private createRegisterFormGroup(): void {
    this.registerFormGroup = this.formBuilder.group({
      username: [null, Validators.required],
      name: [null, Validators.required],
      email: [null, [Validators.required, CommonUtilsService.checkEmail]],
      password: [null, [Validators.required, CommonUtilsService.passwordValidator]],
      confirmPassword: [null, [Validators.required, CommonUtilsService.passwordValidator]],
    }, {
      validator: [
        CommonUtilsService.mustMatch('password', 'confirmPassword'),
        CommonUtilsService.checkEmail('email', true)
      ]
    });
  }

  private createAccountActivationFormGroup(): void {
    this.accountActivationFormGroup = this.formBuilder.group({
      emailOrusername: [null, Validators.required],
      accountActivationCode: [null, Validators.required]
    }, {
      validator: [
        CommonUtilsService.checkEmail('emailOrusername', true)
      ]
    });
  }

  //#endregion

  //#region public methods

  public get registerControls() { return this.registerFormGroup.controls; }

  public get accountActivationControls() { return this.accountActivationFormGroup.controls; }

  public onRegisterClick(): void {
    if (this.registerFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;

    const formData = new FormData();
    formData.append("username", this.registerControls.username.value);
    formData.append("name", this.registerControls.name.value);
    formData.append("email", this.registerControls.email.value);
    formData.append("password", this.registerControls.password.value);
    formData.append("confirmPassword", this.registerControls.confirmPassword.value);
    formData.append("appId", "7f912b2a598f9397d282950787b6b9d0");
    formData.append("postAction", RegisterAction);
    formData.append("isBypassEmailVerificationStep", "N");

    this.subs.sink = this.registerService.createUser(formData)
      .subscribe(
        (authResponse: AuthResponse) => {
          if (authResponse.status) {
            //this.loginService.onLogin(authResponse);
            //this.isSignUp = true;
            // this.router.navigateByUrl('/');
            // this.onCancel();
            this.isSignUpSucc = true;
            this.errorMessage = authResponse.msg;
            // this.errorMessage = "Signup Successfully done. Please check your email for Access Code and come back to Login here.";
            this.router.navigateByUrl(`/donate-per-view/celebbroadway`);
          } else {
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
         // console.log('error: ', error);
          this.showLoading = false;
        }
      );
  }

  public onLogin(): void {
    this.router.navigateByUrl('/auth/login/celebbroadway');
  }

  /*
  public onActivateClick(): void {
    if (this.accountActivationFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;

    const formData = new FormData();
    formData.append("emailOrusername", this.registerControls.emailOrusername.value);
    formData.append("accountActivationCode", this.registerControls.accountActivationCode.value);
    formData.append("appId", APP_ID);
    formData.append("postAction", AccountActivationAction);

    this.subs.sink = this.registerService.accountActivation(formData)
      .subscribe(
        (authResponse: AuthResponse) => {
          if (authResponse.status) {
            // this.isSignUp = true;
            window.location.reload();
            // this.router.navigateByUrl('/');
            // this.onCancel();
          } else {
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
         // console.log('error: ', error);
          this.showLoading = false;
        }
      );
  }*/

  //#endregion

}
