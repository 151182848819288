import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import 'moment-timezone';
import { Location } from '@angular/common';
import { SubSink } from 'subsink';
import { filter,  pairwise } from 'rxjs/operators';

import { Lightbox } from 'ngx-lightbox';
import { LoginComponent } from '../login/login.component'
import { FirsttowatchComponent } from '../firsttowatch/firsttowatch.component'
import { RegisterComponent } from '../register/register.component';
import { PromovideoComponent } from '../promovideo/promovideo.component';
import { DonatePerViewModalComponent } from '../donate-per-view-modal/donate-per-view-modal.component';
import { TermsconditionComponent } from '../termscondition/termscondition.component';
import { ContactusComponent } from '../contactus/contactus.component';
import { StorageService } from '../shared/services/storage.service';
import { AppStorage, StreamType } from '../providers/enum';
import { LoginService } from '../login/login.service';
import { WatchwithticketComponent } from '../watchwithticket/watchwithticket.component';
import { Event1Component } from '../event1/event1.component';
import { Event2Component } from '../event2/event2.component';
import { Event3Component } from '../event3/event3.component';
import { Event4Component } from '../event4/event4.component';
import { MyProfileComponent } from '../my-profile/my-profile.component';
import { EnterTicketCodeComponent } from '../enter-ticket-code/enter-ticket-code.component';
import { LiveVideoPlayerComponent } from '../live-video-player/live-video-player.component';
import { SupportComponent } from '../support/support.component';
import { StreamDetailsService } from '../shared/services/stream-details.service';
import { DonatePerViewService } from '../donate-per-view-modal/services/donate-per-view.service';
import { ActivatedRoute, Router, NavigationEnd, RoutesRecognized  } from '@angular/router';
import { decode } from 'html-entities';
import { ExtravideoComponent } from '../extravideo/extravideo.component';
import { ChannelvideoComponent } from '../channelvideo/channelvideo.component';
import { QRCodeModule } from 'angularx-qrcode';
import { VimeoplayerComponent } from '../vimeoplayer/vimeoplayer.component';
import { RecordcontractComponent } from '../recordcontract/recordcontract.component';
import { RidekindinfoComponent } from '../ridekindinfo/ridekindinfo.component';
import { RidekinditineraryComponent } from '../ridekinditinerary/ridekinditinerary.component';
import { SuccessMessageComponent } from '../success-message/success-message.component';
import { FailMessageComponent } from '../fail-message/fail-message.component';
import { RegisterMessageComponent } from '../register-message/register-message.component';
import { HowtowatchontvComponent } from '../howtowatchontv/howtowatchontv.component';
import { SponsorlinksComponent } from '../sponsorlinks/sponsorlinks.component';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { SuccessMessageLEComponent } from '../success-message-le/success-message-le.component';
import { WatchwithticketcodeComponent } from '../watchwithticketcode/watchwithticketcode.component';
import { EnterTicketCodeAllComponent } from '../enter-ticket-code-all/enter-ticket-code-all.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  //#region variables
  _albums = [];
  public modalRef: BsModalRef;
  public userData: any;
  public streamDetails: any;
  public streamDescFull: any;
  public textBelowPoster: any;
  public streamDetailsFull: any;
  public isUserLogin: boolean = false;
  public isWatchWithAccessCodeClick: boolean = false;
  public isTimeLeftLessOrEqual15: boolean;
  public isLiveEventBuyed: string;
  public viewEventFlag: boolean;
  public DomainName: string;
  public currentTimeZone: string;
  public isPaymentDisable: boolean = false;
  public isWatchEventDisable: boolean = false;
  public showLoading: boolean = true;
  public channelButtonActive = false;
  public extraButtonActive = false;
  public timerInterval: any;
  public contentBackground: string;
  public domain: string;
  public title: string;
  public timeDiff;
  public loginButtonActive = true;
  public paymentButtonActive = true;
  public watcheventButtonActive = true;
  public resendButtonActive = true;
  public eventCounterShow = true;
  public myAngularxQrCode: string = null;
  public eventFooterLink = true;
  public textAboveButtonActive = true;
  public streamType: StreamType;
  public isEventStart: boolean = false;
  public stream_addthis_id: string;
  public contentBackgroundStyle: string;
  public promoLinkActive: boolean;
  public iosAppAvailable: boolean = false;
  public androidAppAvailable: boolean = false;
  public rokuAppAvailable: boolean = false;
  public firetvAppAvailable: boolean = false;
  public pzazAppAvailable: boolean = false;
  public layoutValue: string = null;
  public layoutZero: boolean = true;
  public layoutOne: boolean = true;
  public layoutTwo: boolean = true;
  public layoutThree: boolean = true;
  public layoutFour: boolean = true;
  public layoutFive: boolean = true;
  public layoutSix: boolean = true;
  public layoutSeven: boolean = true;
  public layoutEight: boolean = true;
  public layoutNine: boolean = true;
  public layoutTen: boolean = true;
  public layoutEleven: boolean = true;
  public layoutTwelve: boolean = true;
  public layoutThirteen: boolean = true;
  public streamCorrect: boolean;
  private subs = new SubSink();
  public formS: FormGroup;
  public appStrData: any;
  public urlStripe: any;
  public payCode: any;
  public streamDescShort: any;
  public previousUrl: string = null;
  public  currentUrl: string = null;
  public payMessage: string;
  //#endregion

  //#region life-cycle hook

  public loadScript(url: string) {
    
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
   // alert("hello");
  }

  public loadStyle(url: string) {
    const body = <HTMLDivElement> document.body;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'style.css';
    body.appendChild(link);

    
   
  }


  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private location: Location,
    private storageService: StorageService,
    private loginService: LoginService,
    private streamDetailsService: StreamDetailsService,
    private _lightbox: Lightbox,
    private donatePerViewService: DonatePerViewService) {

      
      this.currentUrl = this.router.url;
      //console.log("curr url===", this.currentUrl);
    

      
      /*
    this.loadStyle('../../assets/css/bootstrap.min.css');
    this.loadStyle('../../assets/css/fontawesome.min.css');
    this.loadStyle('../../assets/css/icofont.css');
   
    this.loadStyle('../../assets/css/slick.css');
    this.loadStyle('../../assets/css/slick-theme.css');
  //  this.loadStyle('../../assets/css/swiper.min.css');
    this.loadStyle('../../assets/css/animate.css');
    this.loadStyle('../../assets/css/hover-min.css');
    this.loadStyle('../../assets/css/smagnific-popuplick.css');
    this.loadStyle('../../assets/css/bootstrap-datepicker.min.css');
    this.loadStyle('../../assets/css/style.css');
    this.loadStyle('../../assets/css/responsive.css');
   */
    

    this.loadScript('../../assets/js/popper.min.js');
    this.loadScript('../../assets/js/bootstrap.min.js');
    
    this.loadScript('../../assets/js/slick.min.js');
    this.loadScript('../../assets/js/slick-animation.min.js');
    this.loadScript('../../assets/js/jquery.magnific-popup.min.js');
   // this.loadScript('../../assets/js/swiper.min.js');
   // this.loadScript('../../assets/js/swiper-custom.js');
    this.loadScript('../../assets/js/bootstrap-datepicker.min.js');
    this.loadScript('../../assets/js/sidebar.js');
   
    
  
    //This code for Live 
    //@ts-ignore
    let fullHost = this.location._platformStrategy._platformLocation.location.hostname;


    // let fullHost = "c2c.swigit.com";
    let parts = fullHost.split('.');
    if (parts && parts.length > 0 && parts[0] !== "localhost") {
      let url = this.router.url;
      this.domain = parts[0];
      //  this.title = url.replace("/", "");
    }


    
    let apptitlel = this.route.snapshot.params.apptitle;
    this.title = apptitlel;
    this.urlStripe = this.route.snapshot.params.url;
    
    let urlData = this.router.url;
   
    
    //alert(this.urlStripe);
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);

    //console.log("userdata=",this.userData);// OUTPUT 1534
    let appStrDt = this.storageService.get(AppStorage.appStripeData, true);
    let finalAmt = "";
    let StreamG ="";
    let appCID = "";
    let verifyPayCode = "";

    if(appStrDt)
    {
     
     
      let appStrDtArr = appStrDt.split(',');
      //console.log("spliaa=", appStrDtArr);

     

      for (let k=0; k<appStrDtArr.length; k++) { 
        if(appStrDtArr[k] == "unit_amount")
        {
          finalAmt = appStrDtArr[k+1];
        }
        if(appStrDtArr[k] == "streamGuid")
        {
          StreamG = appStrDtArr[k+1];
        }
        if(appStrDtArr[k] == "appCId")
        {
          appCID = appStrDtArr[k+1];
        }
        if(appStrDtArr[k] == "payCodeVerify")
        {
          verifyPayCode = appStrDtArr[k+1];
        }
      }
    }
    //this.appStrData = JSON.parse(JSON.stringify(appStrDt));
    //this.appStrData = JSON.parse(appStrDt); 
    //console.log("Stripe d=", this.appStrData);
    // This code for localhost
  
    this.route.queryParams.subscribe(params => {
      // console.log("params=", params);
      if (params && params.domain && params.title) {
        this.domain = params.domain;
        this.title = params.title;
      }
      if (params && params.url) {
        this.urlStripe = params.url;
        this.payCode = params.payCode;
      }
      if (params && params.msl) {
        this.payMessage = params.msl;
      }

      
    }, (error: any) => {
     //  console.log('error: ', error);
    });

   

    //console.log("domain+title=", this.domain + this.title);
   // console.log("url===", this.urlStripe);
    if(this.urlStripe == "success" && verifyPayCode == this.payCode)
    {
      //alert("dsf");
      
      this.addToPurchasedSwigitsSession(StreamG, true);

      this.sendGiftTicketReq(finalAmt, "success", StreamG, appCID);
    }
    if(this.urlStripe == "cancel")
    {
      this.modalRef = this.modalService.show(
        FailMessageComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
      );
    }
    this.myAngularxQrCode = 'https://' + this.domain + '.swigit.com/app/' + this.title;
    this.storageService.set(AppStorage.siteDomain, this.domain, true);
    this.storageService.set(AppStorage.siteTitle, this.title, true);

    //console.log("exit");
  }

  private sendGiftTicketReq(amount, payResult, sGuid, aID) {
   
    var formDataG = new FormData();
    formDataG.append('appId', aID);
    formDataG.append('streamGuid', sGuid);
    formDataG.append('postAction', "buyTicket");
    formDataG.append('userCode', this.userData.userCode);
    formDataG.append('amount', amount);
    formDataG.append('buyInformation', payResult);
    var errMsg = "";
    //console.log('formG=', formDataG);
    this.subs.sink = this.donatePerViewService.dpvTicketGen(formDataG)
      .subscribe(
        (response: any) => {
          //console.log('responseqqqq: ', response);
          if (response.status) {
            errMsg = response.msg;

            let serverURLn ="";
            let urlopen = "";
            if(this.streamDetailsFull.stream_payment_options.local_server_flag == 'Y')
            {
              serverURLn = this.streamDetailsFull.stream_payment_options.local_server_url;
              urlopen = serverURLn + "&msl=1";
            }
            else
            {
              
              serverURLn = this.streamDetailsFull.stream_payment_options.live_server_url;
               urlopen = serverURLn + "?msl=1";
            }  
          
            this.modalRef = this.modalService.show(
              SuccessMessageLEComponent,
              Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
            );

            /*

            let streamU = this.streamDetailsFull.stream_url;
            // alert(streamU);
            let initialState = { url: streamU };
            this.modalRef = this.modalService.show(
              VimeoplayerComponent,
              //PromovideoComponent,
              Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState  })
            );
            */

            //window.open(urlopen, "_self");
            
/*
            this.modalRef = this.modalService.show(
              SuccessMessageComponent,
              Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
            );
            */
            
          } else {
            errMsg = response.msg;
           

            this.modalRef = this.modalService.show(
              FailMessageComponent,
              Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
            );
          }
          localStorage.removeItem(AppStorage.appStripeData);
        },
        (error) => {
          this.modalRef = this.modalService.show(
            FailMessageComponent,
            Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
          );
          localStorage.removeItem(AppStorage.appStripeData);
        }
      );
      
  }

  ngOnInit(): void {

    

    this.previousUrl = document.referrer;
    //alert(this.previousUrl);
    //console.log("history state=", history.length);
    //console.log("domain+title=",this.domain+"-"+this.title);
    this.setStreamDetails();

    if(this.payMessage == "1")
    {
     /* this.modalRef = this.modalService.show(
        SuccessMessageComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
      );
      */
      
      let streamU = this.streamDetailsFull.stream_url;
      // alert(streamU);
      let initialState = { url: streamU };
      this.modalRef = this.modalService.show(
        VimeoplayerComponent,
        //PromovideoComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState  })
      );

    }
    this.createHomeFormGroup();
    
    //this.href = this.router.url;
    this.DomainName = document.location.host;
      // alert(this.DomainName);


    if (this.userData) {
      this.isUserLogin = true;
    }

    if (this.storageService.get(AppStorage.isLiveEventBuyed, true) == 'Y') {
      this.viewEventFlag = true;
    }
    else {
      this.viewEventFlag = false;
    }

    
  }

  public addToPurchasedSwigitsSession(streamCode,  purchaseFlag) {

    /*
    //this.storageService.remove(AppStorage.swigitPurchased, true);
    let SwigPurchasedArr: string[][] = []; 
    let SwigPurchasedData = this.storageService.get(AppStorage.swigitPurchased, true);
    SwigPurchasedArr = JSON.parse(SwigPurchasedData); 
    alert(SwigPurchasedArr);
    let addNew = true;

    if(SwigPurchasedArr)
    {
      for(let i = 0; i < SwigPurchasedArr.length; i++)
      {
        if(SwigPurchasedArr[i][0] === streamCode && purchaseFlag == true)
        {
          SwigPurchasedArr[i][1] = purchaseFlag;
          addNew = false;
        }
      }
    }
    else{
      SwigPurchasedArr = new Array(new Array(streamCode, purchaseFlag));
      //SwigPurchasedArr[0][1] = ;

      
//myArray.push(["value1", "value2"]);

      addNew = false;
    }

    if(addNew == true)
    {
      SwigPurchasedArr = new Array(new Array(streamCode,  purchaseFlag));
    }


    this.storageService.set(AppStorage.swigitPurchased, SwigPurchasedArr, true);
    let SwigPurchasedData2 = this.storageService.get(AppStorage.swigitPurchased, true);
    let SwigPurchasedArr2 = JSON.parse(SwigPurchasedData2); 
    //console.log("swigitpurchased", SwigPurchasedArr2);

    */
  }
  //#endregion

  //#region private methods
  private createHomeFormGroup(): void {
    this.formS = this.formBuilder.group({
      website: [null, [Validators.required]],

      // email: null
    }, {
      validator: []
    });
  }



  private setTimerInterval() {
    let evntStartTime = this.streamDetailsFull.stream_start_datetime;
    evntStartTime = evntStartTime.replace('+05:30', '+00:00');
    var startTime = moment(evntStartTime).format("YYYY-MM-DDTHH:mm:ss");
    // var startTime = moment("2021-11-07T21:15:00+00:00").format("YYYY-MM-DDTHH:mm:ss");
    var zone = this.streamDetailsFull.stream_timezone;
    var eventStartTime = moment.tz(startTime, zone).format();
    let m = moment().utc();
    var duration = moment.duration(moment(startTime).diff(m.format()));
    //@ts-ignore
    this.timeDiff = Math.round(duration / 1000);
    var self = this;
    this.timerInterval = setInterval(function () {
      //@ts-ignore
      if (self.timeDiff <= 0) {
        self.eventCounterShow = false;
        self.isEventStart = true;
        clearInterval(self.timerInterval);
      }
      self.timeDiff = self.timeDiff - 1;
    }, 1000);
  }

  private setMultipleTimerInterval() {
    let data = this.streamDetailsFull.stream_multiple_dates;
    if (data && data.length > 0) {
      for (let i = 0; i < data.length;) {
        let startTime = moment(data[i].eventStDateTime).format("YYYY-MM-DDTHH:mm:ss");
        let zone = data[i].timezoneOffset;
        let eventStartTime = moment.tz(startTime, zone).format();
        let m = moment().utc();
        let duration = moment.duration(moment(eventStartTime).diff(m.format()));
        //@ts-ignore
        this.timeDiff = Math.round(duration / 1000);
        if (this.timeDiff > 0) {
          let self = this;
          this.timerInterval = setInterval(function () {
            //@ts-ignore
            if (self.timeDiff <= 0) {
              self.eventCounterShow = false;
              self.isEventStart = true;
              self.checkEventEndDateTimeTimer(data[i]);
            }
            self.timeDiff = self.timeDiff - 1;
          }, 1000);
          break;
        } else {
          clearInterval(this.timerInterval);
          i++;
        }
      }
    }
  }

  private checkEventEndDateTimeTimer(data) {
    let endTime = moment(data.eventEndDateTime).format("YYYY-MM-DDTHH:mm:ss");
    let zone = data.timezoneOffset;
    let eventEndTime = moment.tz(endTime, zone).format();
    let endDuration = moment.duration(moment(eventEndTime).diff(moment.tz(new Date(), zone).format()));
    //@ts-ignore
    let endTimeDiff = Math.round(endDuration / 1000);
    if (endTimeDiff <= 0) {
      this.eventCounterShow = true;
      this.isEventStart = false;
      clearInterval(this.timerInterval);
      this.setMultipleTimerInterval();
    }
  }

  private setTimer() {
    //let startTime = moment.tz(this.streamDetails.eventStartDateTime, this.streamDetails.eventTimeZone).format();
    //  let startTime = moment(this.streamDetails.eventStartDateTime).format();
    let startTimeD = this.streamDetailsFull.stream_start_datetime;
    startTimeD = startTimeD.replace('+05:30', '+00:00');
    //let startTimeD = "2022-09-29T04:15:00";
    //let startTime = moment.tz("2022-10-01T15:00:00", this.streamDetails.eventTimeZone).format();
    let startTime = moment.tz(startTimeD, this.streamDetails.eventTimeZone).format();
    let m = moment().utc();

    let startDuration = moment.duration(moment(startTime).diff(m.format()));
    let startDays = startDuration.days();
    let startHours = startDuration.hours();
    let startMin = startDuration.minutes();

    let endTime = moment(this.streamDetails.eventEndDateTime).format();
    // let endTime = moment.tz("2022-04-01T08:00:00", this.streamDetails.eventTimeZone).format();
    let endTimeDuration = moment.duration(moment(endTime).diff(m.format()));
    let endDays = endTimeDuration.days();
    let endHours = endTimeDuration.hours();
    let endMin = endTimeDuration.minutes();
    //console.log( startDays+"-"+startHours);
   // console.log("-", startHours);
     /*
     console.log("stream=", this.streamDetails);
     console.log("date", this.streamDetails.eventStartDateTime);
     console.log("new date=", moment.tz(new Date(), this.streamDetails.eventTimeZone).format());
     */
    /*console.log("startDays", startDays);
     console.log("startHours", startHours);
     /*console.log("startMin", startMin);
     console.log("endDays", endDays);
     console.log("endHours", endHours); 
     console.log("endMin", endMin);
    */

    if (endDays <= 0 && endHours <= 0 && endMin <= 0) {
      // this.isPaymentDisable = true;
    }

    //this.isPaymentDisable = true;
    //console.log("payDis", this.isPaymentDisable);

    if (endDays <= 0 && endHours <= 0 && endMin <= 0) {
      this.isWatchEventDisable = true;
    }

    if (startDays <= 0 && startHours <= 2 ) {
      this.isTimeLeftLessOrEqual15 = true;
    } else {
      this.isTimeLeftLessOrEqual15 = false;
    }


    //this.isTimeLeftLessOrEqual15 = true;

    //console.log("EDis", this.isWatchEventDisable);
    //console.log("tmLeft", this.isTimeLeftLessOrEqual15);


    setTimeout(() => {
      this.setTimer()
    }, 1000);
  }

  private async setStreamDetails() {
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);
    this.streamCorrect = true;
    try {
      let result = await this.streamDetailsService.getStreamDetails().toPromise();

     ///  console.log("stream detail:", result.app.all_streams.latest_streams[0]);
      if (result.app.status) {
       // console.log("inn");

        if (result.app.all_streams.latest_streams[0].stream_start_datetime != null) 
        {
          //console.log("inn 888");
          let evntStartTime = result.app.all_streams.latest_streams[0].stream_start_datetime;
          let evntEndTime = result.app.all_streams.latest_streams[0].stream_end_datetime;
          try{
            evntStartTime = evntStartTime.replace('+05:30', '+00:00');
            evntEndTime = evntEndTime.replace('+05:30', '+00:00');
          }
          catch (error) {
            //console.log('error: ', error);
         
          }
          
         // console.log("inn 2");
          this.streamDetails = {
            // eventStartDateTime: moment(result.app.all_streams.latest_streams[0].stream_start_datetime).format("YYYY-MM-DDTHH:mm:ss"),
            // eventEndDateTime: moment(result.app.all_streams.latest_streams[0].stream_end_datetime).format("YYYY-MM-DDTHH:mm:ss"),
            // eventStartDateTime: moment("2021-11-07T21:15:00+00:00").format("YYYY-MM-DDTHH:mm:ss"),
            // eventEndDateTime: moment("2021-11-08T21:15:00+00:00").format("YYYY-MM-DDTHH:mm:ss"),
            eventStartDateTime: moment(evntStartTime).format("YYYY-MM-DDTHH:mm:ss"),
            eventEndDateTime: moment(evntEndTime).format("YYYY-MM-DDTHH:mm:ss"),
            eventTimeZone: result.app.all_streams.latest_streams[0].stream_timezone,
            eventTrailerUrl: result.app.all_streams.latest_streams[0].stream_trailer_url,
            eventStreamUrl: result.app.all_streams.latest_streams[0].stream_url
          }
          // eventTimeZone: result.app.all_streams.latest_streams[0].stream_timezone,

          //eventTrailerUrl: result.app.all_streams.latest_streams[0].stream_trailer_url,
          //eventStreamUrl: result.app.all_streams.latest_streams[0].stream_url

        }
        else {
        // console.log("inn 3");
          this.streamDetails = {

            eventTimeZone: result.app.all_streams.latest_streams[0].stream_timezone,
            eventTrailerUrl: result.app.all_streams.latest_streams[0].stream_trailer_url,
            eventStreamUrl: result.app.all_streams.latest_streams[0].stream_url
          }
        }
        this.streamDetailsFull = result.app.all_streams.latest_streams[0];
        this.streamType = this.streamDetailsFull.stream_type;


        
       // let result2 = this.streamDetailsService.trackUserAccessHome(this.streamDetailsFull.app_code).toPromise();
       this.layoutValue = this.streamDetailsFull.stream_design.stream_layout_id;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '0')
          this.layoutZero = true;
        else
          this.layoutZero = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '1')
          this.layoutOne = true;
        else
          this.layoutOne = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '2')
          this.layoutTwo = true;
        else
          this.layoutTwo = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '3')
          this.layoutThree = true;
        else
          this.layoutThree = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '4')
          this.layoutFour = true;
        else
          this.layoutFour = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '5')
          this.layoutFive = true;
        else
          this.layoutFive = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '6')
          this.layoutSix = true;
        else
          this.layoutSix = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '7')
          this.layoutSeven = true;
        else
          this.layoutSeven = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '8')
          this.layoutEight = true;
        else
          this.layoutEight = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '9')
          this.layoutNine = true;
        else
          this.layoutNine = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '10')
          this.layoutTen = true;
        else
          this.layoutTen = false;
          
        if (this.streamDetailsFull.stream_design.stream_layout_id === '11')
          this.layoutEleven = true;
        else
          this.layoutEleven = false;

             
        if (this.streamDetailsFull.stream_design.stream_layout_id === '12')
          this.layoutTwelve = true;
        else
          this.layoutTwelve = false;

        if (this.streamDetailsFull.stream_design.stream_layout_id === '13')
          this.layoutThirteen = true;
        else
          this.layoutThirteen = false;

        if (this.streamDetailsFull.stream_design.extra_video_btn_available === 'Y')
          this.extraButtonActive = true;
        else
          this.extraButtonActive = false;

        if (this.streamDetailsFull.stream_design.channel_video_btn_available === 'Y')
          this.channelButtonActive = true;
        else
          this.channelButtonActive = false;

        if (this.streamDetailsFull.stream_design.payment_btn_available === 'Y')
          this.paymentButtonActive = true;
        else
          this.paymentButtonActive = false;

        if (this.streamDetailsFull.stream_design.watch_event_btn_available === 'Y')
          this.watcheventButtonActive = true;
        else
          this.watcheventButtonActive = false;

        if (this.streamDetailsFull.stream_design.login_register_btn_available === 'Y') {
          this.loginButtonActive = true;
          if (this.streamDetailsFull.stream_design.resend_btn_available === "Y")
            this.resendButtonActive = true;
          else
            this.resendButtonActive = false;
        } else {
          this.loginButtonActive = false;
          this.resendButtonActive = false;
        }
        if (this.streamDetailsFull.stream_design.show_event_countdown === 'Y')
          this.eventCounterShow = true;
        else
          this.eventCounterShow = false;

        if (this.streamDetailsFull.stream_design.footer_link_or_text === 'L')
          this.eventFooterLink = true;
        else
          this.eventFooterLink = false;

        if (this.streamDetailsFull.stream_design.text_above_buttons_available === 'Y')
          this.textAboveButtonActive = true;
        else
          this.textAboveButtonActive = false;


        if (this.streamDetailsFull.stream_design.promo_btn_type === 'V')
          this.promoLinkActive = false;
        else
          this.promoLinkActive = true;

        if (this.streamDetailsFull.stream_design.ios_app_available === 'Y')
          this.iosAppAvailable = true;
        else
          this.iosAppAvailable = false;

        if (this.streamDetailsFull.stream_design.android_app_available === 'Y')
          this.androidAppAvailable = true;
        else
          this.androidAppAvailable = false;

        if (this.streamDetailsFull.stream_design.roku_app_available === 'Y')
          this.rokuAppAvailable = true;
        else
          this.rokuAppAvailable = false;

        if (this.streamDetailsFull.stream_design.pzaz_app_available === 'Y')
          this.pzazAppAvailable = true;
        else
          this.pzazAppAvailable = false;

        this.stream_addthis_id = "ra-5e4b882ee33c483d";


        if (this.streamDetailsFull.stream_design.content_background_img_available === 'Y') {
          // background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(images/understar_background.webp);
          this.contentBackgroundStyle = "background: url(" + this.streamDetailsFull.stream_design.content_background_image_url + ") ; ";
          if (this.streamDetailsFull.stream_design.content_background_image_style !== null)
            this.contentBackgroundStyle = this.contentBackgroundStyle + this.streamDetailsFull.stream_design.content_background_image_style;
        }
        else
          this.contentBackgroundStyle = "";

        //console.log("back=", this.contentBackgroundStyle);

        this.streamDescFull = this.streamDetailsFull.stream_desc_full;
        this.streamDescFull = decode(this.streamDescFull, { level: 'html5' });

        
        this.streamDescShort = this.streamDetailsFull.stream_short_des;
        this.streamDescShort = decode(this.streamDescShort, { level: 'html5' });

        this.textBelowPoster = this.streamDetailsFull.stream_design.text_below_poster;
        this.textBelowPoster = decode(this.textBelowPoster, { level: 'html5' });


        if (this.streamDetailsFull.stream_design.content_background_img_available === 'Y')
          this.contentBackground = this.streamDetailsFull.stream_poster;
        else
          this.contentBackground = "";
        //  background: url(https://imagescdn2.swigit.com/LiveEvent/HDPoster/angielostgirls) no-repeat #1e1e1e;
        this.storageService.set(AppStorage.streamDetails, JSON.stringify(result.app.all_streams.latest_streams), true);
        //this.storageService.get(AppStorage.streamDetails, true);

        //console.log("full =", this.streamDetailsFull);
        if (result.app.all_streams.latest_streams[0].stream_start_datetime != null) {
          setTimeout(() => {
            this.showLoading = false;
            this.setTimer();
            // For Timer Counter  
            if (this.streamType === StreamType.Multiple) {
              this.setMultipleTimerInterval();
            } else {
              this.setTimerInterval();
            }
          }, 1000);
        }
        else {
          this.showLoading = false;
        }
      }
      else {
        this.streamCorrect = false;
      }
    } catch (error) {
      //console.log('error: ', error);
      this.showLoading = false;
      this.streamCorrect = false;
    }

    this.loadScript('../../assets/js/owl.carousel.min.js');
    this.loadScript('../../assets/js/main.js');

    const src = this.streamDetailsFull.stream_design.poster_image_url;
      const caption = '';
      const thumb = '';
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };
 
      this._albums.push(album);
    // this.isWatchEventDisable = false;
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  
  //#endregion

  //#region public methods

  public secondsToTime(seconds) {
    var minutes = 0;
    var hours = 0;
    var remainMin = 0;
    var remainSec = 0;
    var days = 0;

    //  console.log("input time=", input);
    if (seconds != undefined && seconds > 0) {
      minutes = Math.floor(seconds / 60);
      hours = Math.floor(minutes / 60);
      remainMin = minutes - (hours * 60);
      remainSec = seconds - (minutes * 60);
      if (hours > 23) {
        days = Math.floor(hours / 24);
        hours = hours - (days * 24);
      }
    }

    return (days > 0 ? days + "(d) : " : "") + (hours < 10 ? ('0' + hours) : hours) + '(h) : ' + (remainMin < 10 ? ('0' + remainMin) : remainMin) + '(m) : ' + (remainSec < 10 ? ('0' + remainSec) : remainSec) + '(s)';
  }

  public firstToWatchLink(): void {
    this.modalRef = this.modalService.show(
      FirsttowatchComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }

  public participateLink(): void {
    this.modalRef = this.modalService.show(
      RecordcontractComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }

  public openLoginModal(): void {
    this.modalRef = this.modalService.show(
      LoginComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }

  public openRegisterModal(): void {
    this.modalRef = this.modalService.show(
      RegisterComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }

  public openRegisterModalD(): void {
    let initialState = { fromP: 'D' };
    //alert("dddd");
    this.modalRef = this.modalService.show(
      RegisterComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md', initialState  })
     
    );
  }

  public openRegisterModalN(): void {
    let initialState = { fromP: 'N' };
    //alert("dddd");
    this.modalRef = this.modalService.show(
      RegisterComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md', initialState  })
     
    );
  }

  public openRegisterMessageModal(): void {
    this.modalRef = this.modalService.show(
      RegisterMessageComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }
  

  public openSupportModal(): void {
    this.modalRef = this.modalService.show(
      SupportComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
    );
  }

  public onDealsClick(): void {
    this.modalRef = this.modalService.show(
      SponsorlinksComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray  modal-lg' })
    );
  }

  public onPromoClick(): void {

    // this.storageService.set(AppStorage.streamPlayUrl, this.streamDetailsFull.stream_trailer_url, true);
    // //console.log("promo link = ", this.streamDetailsFull.stream_trailer_url);
    // if (this.streamDetailsFull.stream_design.promo_btn_player === 'V') {
    //   // alert("sdfsf");
    //   this.modalRef = this.modalService.show(
    //     VimeoplayerComponent,
    //     Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg' })
    //   );
    // }
    // else {
    //   this.modalRef = this.modalService.show(
    //     PromovideoComponent,
    //     Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg' })
    //   );
    // }


    this.modalRef = this.modalService.show(
      PromovideoComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg' })
    );
  }

  public onPromoBtnClick(streamURL: any): void {
    let initialState = { url: streamURL };
    let userC = "";
    if(this.userData)
      userC = this.userData.userCode;
    else  
      userC = "";

    let result2 = this.streamDetailsService.trackUserAccessPromo(this.streamDetailsFull.app_code, userC).toPromise();

    if (this.streamDetailsFull.stream_design.promo_btn_player === 'V') {
      //alert("sdfsf"+streamURL);
      this.modalRef = this.modalService.show(
        VimeoplayerComponent,
        //PromovideoComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
      );
    }
    else {
      this.modalRef = this.modalService.show(
        PromovideoComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
      );

    }

  }

  public onVideoLinkClick(videoURL, videoType)
  {
      let initialState = { url: videoURL };
      //alert(videoType);
      if(videoType == 'V')
      {
        this.modalRef = this.modalService.show(
          VimeoplayerComponent,
          //PromovideoComponent,
          Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
        );
      }
      else{
        this.modalRef = this.modalService.show(
          PromovideoComponent,
           Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
      );
      }

  }

  public swigitVideoClick(videoURL, videoType)
  {
      let initialState = { url: videoURL };
      //alert(videoType);
      if(videoType == 'V')
      {
        this.modalRef = this.modalService.show(
          VimeoplayerComponent,
          //PromovideoComponent,
          Object.assign({ ignoreBackdropClick: true }, { class: 'gray  modal-lg', initialState })
        );
      }
      else{
        this.modalRef = this.modalService.show(
          PromovideoComponent,
           Object.assign({ ignoreBackdropClick: true }, { class: 'gray  modal-lg', initialState })
      );
      }

  }

  public onPromoVJClick(): void {

    this.storageService.set(AppStorage.streamPlayUrl, "https://storefreestyle.teleosmedia.com/vod/freestyle/AngieLostGirlsTRAILER2020/playlist.m3u8", true);
    //console.log("promo link = ", this.streamDetailsFull.stream_trailer_url);


    this.modalRef = this.modalService.show(
      PromovideoComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg' })
    );


  }

  public onExtraButtonClick(): void {
    this.storageService.set(AppStorage.streamPlayUrl, this.streamDetailsFull.stream_design.extra_video_btn_url, true);
    //  this.storageService.set(AppStorage.streamPlayUrl, this.streamDetailsFull.stream_trailer_url, true);
    //console.log("extra link = ", this.streamDetailsFull.stream_design.extra_video_btn_url);

    if (this.streamDetailsFull.stream_design.extra_video_btn_type === 'V') {
      if (this.streamDetailsFull.stream_design.extra_video_player === 'V') {
        this.modalRef = this.modalService.show(
          VimeoplayerComponent,
          Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg' })
        );
      }
      else {
        this.modalRef = this.modalService.show(
          PromovideoComponent,
          Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg' })
        );
      }
    }
    else {

      this.openExtraLink();
    }

  }

  public goBackURL(): void {

    window.open("https://"+ this.domain +".swigit.com/sbundle?title=sweebundle-sm");
  }

  public onChannelButtonClick(): void {

    // alert("dsf");

    if (this.streamDetailsFull.stream_design.channel_video_player === 'L') {
      var urltopen = this.streamDetailsFull.stream_design.channel_video_btn_url;
      // alert(urltopen);
      window.open(urltopen, "_blank");
    }
    else {
      var urltopen = this.streamDetailsFull.stream_design.channel_video_btn_url;
      // alert(urltopen);
      this.modalRef = this.modalService.show(
        ChannelvideoComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg' })
      );
    }
  }

  public onDonatePerViewClick(): void {
    if (this.userData && this.userData.userCode) {
      this.modalRef = this.modalService.show(
        DonatePerViewModalComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
      );
    } else {
      //this.openLoginModal();
      this.openRegisterModalD();
    }
  }

  public openTicketCodeComp(): void {
    
      this.modalRef = this.modalService.show(
        EnterTicketCodeComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
      );
   
  }



  public async onWatchWithTCClick() {


    //console.log("isTimeLeftLessOrEqual15=", this.isTimeLeftLessOrEqual15);
    if (this.isTimeLeftLessOrEqual15) {
      if (this.userData && this.userData.userCode) {
        let eventBuyed;
        try {
          let result = await this.loginService.checkEventIsBuyed(this.userData.userCode).toPromise();
          if (result.app.status) {
            eventBuyed = result.app.all_streams.current_page_stream.is_live_event_buyed
          }
        } catch (error) {
          // console.log('error: ', error);
        }
        if (eventBuyed === "Y") {

          //  alert("sdfsf"+this.streamDetailsFull.stream_url);
          let initialState = { url: this.streamDetailsFull.stream_url };
          this.modalRef = this.modalService.show(
            VimeoplayerComponent,
            //PromovideoComponent,
            Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
          );

          /* this.modalRef = this.modalService.show(
              LiveVideoPlayerComponent,
              Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullWidth modal-lg' })
            );*/
        } else {
          this.modalRef = this.modalService.show(
            EnterTicketCodeComponent,
            Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
          );
        }
      } else {
        this.openLoginModal();
      }
    } else {
      this.modalRef = this.modalService.show(
        WatchwithticketComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
      );
      setTimeout(() => {
        this.modalRef.hide();
      }, 10000);
    }


  }

  public onHowtowatchonTVClick(): void {
    this.modalRef = this.modalService.show(
      HowtowatchontvComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray  modal-md' })
    );
  }

  public async onWatchWithTCClickFree() {


    //console.log("isTimeLeftLessOrEqual15=", this.isTimeLeftLessOrEqual15);
   // this.isTimeLeftLessOrEqual15 = true;
    if (this.isTimeLeftLessOrEqual15) {
      
      let result2 = this.streamDetailsService.trackUserAccessWatch(this.streamDetailsFull.app_code).toPromise();
          //  alert("sdfsf"+this.streamDetailsFull.stream_url);
          //console.log("resul=", result2);
          let initialState = { url: this.streamDetailsFull.stream_url };
          this.modalRef = this.modalService.show(
            VimeoplayerComponent,
            //PromovideoComponent,
            Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
          );

         
     
    } else {
      this.modalRef = this.modalService.show(
        WatchwithticketComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
      );
      setTimeout(() => {
        this.modalRef.hide();
      }, 40000);
    }


  }
  public async onWatchWithTCC() {


    //console.log("isTimeLeftLessOrEqual15=", this.isTimeLeftLessOrEqual15);
   // this.isTimeLeftLessOrEqual15 = true;
    
      
      let result2 = this.streamDetailsService.trackUserAccessWatch(this.streamDetailsFull.app_code).toPromise();
         

         
     
 
      this.modalRef = this.modalService.show(
        WatchwithticketComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg' })
      );
     


  }

  public async onWatchWithTCClickC2C(strURL) {


    //console.log("isTimeLeftLessOrEqual15=", this.isTimeLeftLessOrEqual15);
    this.isTimeLeftLessOrEqual15 = true;
    if (this.isTimeLeftLessOrEqual15) 
    {
      
      let result2 = this.streamDetailsService.trackUserAccessWatch(this.streamDetailsFull.app_code).toPromise();

      if(this.streamDetailsFull.stream_user_purchased)
      {
          //  alert("sdfsf"+this.streamDetailsFull.stream_url);
          //console.log("resul=", result2);
          let initialState = { url: strURL};
          this.modalRef = this.modalService.show(
            VimeoplayerComponent,
            //PromovideoComponent,
            Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
          );
      }
      else
      {
        this.modalRef = this.modalService.show(
          EnterTicketCodeAllComponent,
          Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
        );
      }
          

         
     
    } else {
      this.modalRef = this.modalService.show(
        WatchwithticketcodeComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
      );
     
    }


  }

  public event1Click(): void {
    this.modalRef = this.modalService.show(
      Event1Component,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray  modal-lg' })
    );
    
  }

  public event2Click(): void {
    this.modalRef = this.modalService.show(
      Event2Component,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray  modal-lg' })
    );
    
  }
  public event3Click(): void {
    this.modalRef = this.modalService.show(
      Event3Component,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray  modal-lg' })
    );
    
  }
  public event4Click(): void {
    this.modalRef = this.modalService.show(
      Event4Component,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray  modal-lg' })
    );
    
  }

  public alertMessageClick(): void {
    this.modalRef = this.modalService.show(
      WatchwithticketComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray  modal-lg' })
    );
    setTimeout(() => {
      this.modalRef.hide();
    }, 20000);
  }

  public onWatchEventClick(): void {
    this.modalRef = this.modalService.show(
      LiveVideoPlayerComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullWidth modal-lg' })
    );
  }

  public onTermsClick(): void {
    this.modalRef = this.modalService.show(
      TermsconditionComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
    );
  }

  public openContactUsModal(): void {
    this.modalRef = this.modalService.show(

      ContactusComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
    );
  }

  public openMyProfileModal(): void {
    this.modalRef = this.modalService.show(
      MyProfileComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }

  public openRideKindInfoModal(): void {
    this.modalRef = this.modalService.show(
      RidekindinfoComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
    );
  }

  public openRideKindItinerary(): void {
    this.modalRef = this.modalService.show(
      RidekinditineraryComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
    );
  }

  public onLogout(): void {
    this.loginService.onLogout();
    window.location.reload();
  }

  public onMyProfileClick(): void {
    this.router.navigateByUrl(`/myprofile`);
  }

  public onMyPaymentHistoryClick(): void {
    this.router.navigateByUrl(`/mypaymenthistory`);
  }

  public openLink(): void {
    var urltopen = this.streamDetailsFull.stream_design.promo_btn_link;
    //alert(urltopen);
    window.open(urltopen, "_blank");

  }

  public openExtraLink(): void {
    var urltopen = this.streamDetailsFull.stream_design.extra_video_btn_url;
    //alert(urltopen);
    window.open(urltopen, "_blank");

  }
  public openExtraLink2(): void {
    var urltopen = this.streamDetailsFull.stream_design.extra_video_btn_url_2;
    //alert(urltopen);
    window.open(urltopen, "_blank");

  }


  public changeLang(e): void {
    // alert(e.target.value);
    if (e.target.value !== "") {
      let initialState = { url: e.target.value };



      //alert("sdfsf"+streamURL);
      this.modalRef = this.modalService.show(
        VimeoplayerComponent,
        //PromovideoComponent,
        Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
      );

    }

  }


  public onSYVButtonClick(typeLink) {
    if (typeLink == 'L') {
      var urltopen = this.streamDetailsFull.stream_design.extra_video_btn_url_2;
      //alert(urltopen);
      window.open(urltopen, "_blank");
    }
    else {
      //alert(this.streamDetailsFull.stream_design.extra_video_btn_url_2);
      if (this.streamDetailsFull.stream_design.extra_video_btn_url_2 !== "") {
        let initialState = { url: this.streamDetailsFull.stream_design.extra_video_btn_url_2 };



        //alert("sdfsf"+initialState);
        this.modalRef = this.modalService.show(
          VimeoplayerComponent,
          //PromovideoComponent,
          Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState })
        );

      }
    }

  }


  public openQNALink(): void {
    var urltopen = "https://watchbeem.com/cowatch/celebration-broadway-jewish-composers";
    //alert(urltopen);
    window.open(urltopen, "_blank");

  }

  public openLinkAll(urltopen) {

    window.open(urltopen, "_blank");

  }

  public swigitOpenLink(pixelLink, openLink) {
    var x = document.createElement("IMG");
    x.setAttribute("src", pixelLink);
    x.style.display = "none";
    //alert("sdfsdf");

    window.open(openLink, '_blank');
  }



  public resendTicketCode() {
    this.showLoading = true;
    //alert("sdf");
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);
    this.streamCorrect = true;
    try {
      let appCode = this.storageService.get(AppStorage.appCodeLS, true);
      var formDataC = new FormData();
      // console.log("str = ", this.streamDetailsFull);
      formDataC.append("userCode", this.userData.userCode);
      formDataC.append("menuGuid", this.streamDetailsFull.menu_guid);
      formDataC.append("streamGuid", this.streamDetailsFull.stream_guid);
      //formDataC.append("appId", appCode);
      formDataC.append("appId", this.streamDetailsFull.app_code);

      this.subs.sink = this.donatePerViewService.sendTicketCode(formDataC)
        .subscribe(
          async (response: any) => {
            // console.log('response: ', response);
            this.showLoading = false;
            if (response.status) {
              this.showLoading = false;
            }
          });


    } catch (error) {
      //console.log('error: ', error);
      this.showLoading = false;
      //  this.streamCorrect = false;
    }


  }

  
  //#endregion

}
