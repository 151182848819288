<div class="modal-content">

  <div class="modal-body">
    <div class="card p-4 login">
      <div class="card-body" id="login">
        <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()">X</button>
        <h1 class="loginLabel">Enter Ticket Code </h1>
        <br>
        <form [formGroup]="ticketCodeFormGroup" autocomplete="off">
          <!-- <div *ngIf="!forgetPasswordMode"> -->
          <div class="form-group">

            <div id="ticket-code">
              <label class="form-label mb-1 loginLabel" for="inputTicketCode">Ticket Code <span
                  class="required-field">*</span></label>
              <input nbInput fullWidth fieldSize="giant" type="text" class="form-control" id="inputTicketCode"
                placeholder="Ticket Code" formControlName="activationcode"
                [ngClass]="{ 'is-invalid': isCheckValidation && ticketCodeControls.activationcode.errors }">
              <small class="form-error-text" *ngIf="(isCheckValidation && !!ticketCodeControls?.activationcode.errors) ||
                              (!!ticketCodeControls?.activationcode.touched && !!ticketCodeControls?.activationcode.invalid)">
                <ng-container class="pt-2" *ngIf="!!ticketCodeControls?.activationcode.errors.required">
                  {{ ticketCodeMessage.activationcode.errors.required }}
                </ng-container>
              </small>
            </div>
          </div>


          <div class="text-center">
            <p id="error-message">
              {{ errorMessage }}
            </p>
          </div>

          <div id="login-box" style="padding-top: 10px;">
            <div class="d-flex justify-content-center">
              <button id="login-btn" class="btn px-4" *ngIf="isSignUp" (click)="onSubmitClick()">Submit</button>

              <button id="cancel-btn" class="btn px-4" (click)="onCancel()">Cancel</button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>